/* Updated Background color for the entire section */
.bg-theme-dark {
    background-color: #1e1e2e; /* Deep indigo */
}

/* Service Card hover effect with a vibrant overlay */
.service-card {
    position: relative;
    overflow: hidden;
    background: white;
    color: #1e1e2e;
}

.service-card::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(32deg, #069408 20%, #72c470 100%);
    z-index: 0;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
}

.service-card:hover::before {
    transform: translateY(0);
}

/* Text colors for readability and harmony */
.text-theme-blue {
    color: #e0e0f0; /* Soft white */
}

.text-theme-purple {
    color: #c5b3e8; /* Muted lavender */
}

.bg-theme-green {
    border-color: #02570bcd;
}

/* Button styles */
.border-theme-purple {
    border-color: #a249ed;
}

.border-theme-green {
    border-color: #04b515;
}

.hover\:bg-theme-purple:hover {
    background: linear-gradient(32deg, #a249ed 20%, #6131e8 100%);
}

.hover\:text-white:hover {
    color: white;
}

/* Optional additional padding for text readability on hover */
.service-card > * {
    position: relative;
    z-index: 1;
    padding: 0 0.5rem;
}

/* Continuous zoom animation */
@keyframes zoomInOut {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  
  .zoom-section {
    opacity: 0;
    transform: scale(0.9); /* Start slightly zoomed out */
    transition: opacity 0.9s ease-out;
  }
  
  .zoom-in {
    opacity: 1;
    animation: zoomInOut 3s ease-in-out infinite; /* Make it infinite for a continuous effect */
  }
  