@tailwind base;
@tailwind components;
@tailwind utilities;

section {
  @apply mb-28;
}

.active-link {
  @apply text-green-500;
  /* @apply text-theme-purple; */
}

.header {
  @apply flex flex-col py-4 mb-4 justify-between;
}

.hero {
  @apply px-5 flex flex-col mx-auto;
}

.portofolio-card:hover .img-hover {
  @apply opacity-25;
}

@media (min-width: 1024px) {
  .header {
    @apply container w-full flex-row py-8 px-10 mt-2 mb-4 mx-auto items-center content-center border-b border-gray-100;
  }

  .hero {
    @apply flex-row mx-auto;
  }
}

