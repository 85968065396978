.service-details-container {
  display: flex;
  flex-direction: row;
  background-color: #f0fff4;
  padding: 20px;
  color: "black";
  padding-top: 80px;
}
.scrollable-content {
  padding: 20px;
}

.right-form {
  width: 20%;
    padding: 1rem;
    position: sticky;
    top: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px
}

.loading-wrapper, .error-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-wrapper .spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ccc;
  border-top: 5px solid #4caf50;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.quill-container {
  border-radius: 8px;
  padding: 10px;
  background: #f0fff4;
  overflow-y: auto;
  color: black;
}


@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* For WebKit Browsers (Chrome, Safari, Edge) */
.scrollable-content {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.scrollable-content::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* Optional styling for better user experience */
.scrollable-content {
  overflow-y: scroll; /* Make the area scrollable */
  overflow-x: hidden; /* Avoid horizontal scrolling */
}

.service-details-container {
  display: flex;
  flex-wrap: wrap; /* Enables wrapping for smaller screens */
  gap: 16px; /* Adds spacing between sections */
}

.scrollable-content {
  flex: 1; /* Takes up available space */
  min-width: 300px; /* Ensures content doesn't get too small */
}

.right-form {
  flex: 0 0 300px; /* Fixed width on larger screens */
  min-width: 300px;
}

/* For smaller screens */
@media screen and (max-width: 768px) {
  .service-details-container {
    flex-direction: column; /* Stacks the content vertically */
  }

  .right-form {
    margin-top: 16px; /* Adds spacing between sections */
  }
}

